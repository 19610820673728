import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "file-uploader",
      "style": {
        "position": "relative"
      }
    }}>{`File Uploader`}</h1>
    <p>{`File Uploader allows the user to transfer a file or submit content of their own. Either natively using a file input or drag and drop.`}</p>
    <p>{`File Uploader shows the progress of the upload and provides feedback on the upload status.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Always keep the file name visible.`}</li>
      <li parentName="ul">{`Provide information about file type, size, and upload speed or progress when possible.`}</li>
    </ul>
    <h2 {...{
      "id": "file-uploader-status",
      "style": {
        "position": "relative"
      }
    }}>{`File Uploader status`}</h2>
    <table>
  <tbody>
    <tr>
      <th width={160}>Loading</th>
      <td>Inform a user of the upload progress. Provide informations like file size and upload speed estimate.</td>
    </tr>
    <tr>
      <th>Error</th>
      <td>Any errors that may occur with the file upload should appear as an Inline Error.</td>
    </tr>
    <tr>
      <th>Complete</th>
      <td>
        When completed, the thumbnail will be shown in the list, and a success icon and Success message indicates the
        upload state.
      </td>
    </tr>
    <tr>
      <th>Paused</th>
      <td>Only for big files. Let the user pause and unpause the upload.</td>
    </tr>
  </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      